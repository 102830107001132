import * as Sentry from '@sentry/react';
import httpClient from 'common/http/httpClient';
import {
  Company,
  ICompanyService,
  EventData,
  DataFundraising,
  UndoReviewData,
  HeadlineFeedbackData,
} from './company.model';

interface Response {
  data: { data: any };
}

const CompanyService = (): ICompanyService => {
  return {
    getOneCompany: async (companyId: string): Promise<Company> => {
      try {
        const response = (await httpClient.get(`/company/${companyId}`)) as Response;
        return response.data.data as Company;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    getAllCompanies: async (): Promise<string[]> => {
      try {
        const response = (await httpClient.get(`/company/all`)) as Response;
        return response.data.data as string[];
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    submitEvent: async (data: EventData): Promise<any> => {
      try {
        const response = (await httpClient.post(`/company/event`, { data })) as Response;
        return response.data.data as any;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    submitHeadlineFeedback: async (data: HeadlineFeedbackData): Promise<any> => {
      try {
        const response = (await httpClient.post(`/headline/feedback/${data.headlineId}`, {
          data,
        })) as Response;
        return response.data.data as any;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    undoReview: async (data: UndoReviewData): Promise<any> => {
      try {
        const { sourcerId, companyId } = data;
        const response = (await httpClient.delete(
          `/sourcer/${sourcerId}/undo-event/${companyId}`,
        )) as Response;
        return response.data.data as any;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },

    getFundraisingData: async ({ snapshotId, companyId, page }: DataFundraising): Promise<any> => {
      try {
        const response = (await httpClient.get(
          `/company/fundraising?snapshotId=${snapshotId}&companyId=${companyId}&page=${page}`,
        )) as Response;
        return response.data.data as any;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    },
  };
};

export default CompanyService();
