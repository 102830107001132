import React from 'react';
import ReactDOM from 'react-dom/client';
import setupSentry from 'config/sentry/sentryConfig';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import theme from 'theme/theme';

import Router from 'routes/Router';
import store from 'store';

import 'common/language';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'index.scss';

setupSentry();

posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY || '', {
  api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID as string}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <ToastContainer
              style={{
                padding: '0',
                width: '20rem',
                marginTop: '4.5rem',
              }}
            />
            <RouterProvider router={Router} />
          </Provider>
        </ThemeProvider>
      </GoogleOAuthProvider>
    </PostHogProvider>
  </React.StrictMode>,
);
