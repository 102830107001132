import useMediaQuery from '@mui/material/useMediaQuery';
import Header from 'components/organisms/header/Header';
import FundraisingHistoryCard from 'components/molecules/FundraisingHistoryCard/FundraisingHistoryCard';
import HeadcountCard from 'components/molecules/HeadcountCard/HeadcountCard';
import AffinityNotesCard from 'components/molecules/AffinityNotesCard/AffinityNotesCard';
import StickyNavbar from 'components/molecules/StickyNavbar/StickyNavbar';
import WebsiteIcon from 'components/atoms/WebsiteIcon/WebsiteIcon';
import CollapsibleDescription from 'components/atoms/CollapsibleDescription/CollapsibleDescription';
import { ButtonAction, DONT_REASONS, SKIP_REASONS, ToastType } from 'utils/constants';
import useCompanies from 'hooks/useCompanies';
import ReasonsDialog from 'components/organisms/ReasonsDialog/ReasonsDialog';
import { useEffect, useState } from 'react';
import Container from 'components/molecules/Container/Container';
import InstagramCard from 'components/molecules/InstagramCard/InstagramEmbed';
import PageLoading from 'components/molecules/PageLoading/PageLoading';
import useAppNavigation from 'hooks/useAppNavigation';
import AffinityList from 'components/molecules/AffinityList/AffinityList';
import useUser from 'hooks/useUser';
import UndoToast from 'components/atoms/UndoToast/PreviousCompanyButton';
import posthog from 'posthog-js';
import HeadlinesList from 'components/molecules/HeadlinesList/HeadlinesList';
import useSkeletonLoader from 'hooks/useSkeletonLoader';
import { Skeleton } from '@mui/material';
import DangerFeedbackToast from 'components/molecules/DangerFeedbackToast/DangerFeedbackToast';
import SuccessFeedbackToast from 'components/molecules/SuccessFeedbackToast/SuccessFeedbackToast';
import useFeedbackToast from 'hooks/useFeedbackToast';
import ProspectOrigin from 'components/molecules/ProspectOrigin/ProspectOrigin';
import InfoSidebar from 'components/molecules/InfoSidebar/InfoSidebar';
import classNames from 'classnames';

import './CompanyProfilePage.scss';

const CompanyProfilePage = () => {
  const { fetchAvailableUsers, availableUsers, user } = useUser();
  const { fetchAllCompanies, companiesAssignments, company } = useCompanies();
  const { toast, triggerToast, hideToast } = useFeedbackToast();
  const { loading } = useSkeletonLoader();
  const [isModalOpen] = useState(false);
  const [isDontPursueModalOpen, setIsDontPursueModalOpen] = useState(false);
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const navigate = useAppNavigation();

  const toggleDialogs = (actionType: string) => {
    switch (actionType) {
      case ButtonAction.DontPursue:
        setIsDontPursueModalOpen(!isDontPursueModalOpen);
        break;
      case ButtonAction.Skip:
        setIsSkipModalOpen(!isSkipModalOpen);
        break;
      default:
        break;
    }
  };

  // const toggleReasonsModal = () => {
  //   toggleDialogs(ButtonAction.DontPursue);
  // };

  const getInstagramProfile = () => {
    if (!company) return '';
    const instagram = company.websites?.find((website: string) => website.includes('instagram'));
    if (instagram) {
      return instagram.split('/')[3];
    }
    return '';
  };

  const handleReachOut = () => triggerToast(ToastType.SUCCESS);

  /* 
  Commented by request https://github.com/Anthos-Capital/Forge-FE/issues/122
  useEffect(() => {
    if (!localStorage.getItem('showtutorial')) {
      setIsGuideDialogOpen(true);
    }
  }); */

  useEffect(() => {
    fetchAllCompanies();
    fetchAvailableUsers();
  }, []);

  useEffect(() => {
    if (
      companiesAssignments &&
      companiesAssignments.filter((assignment) => !assignment.hasReview).length > 0
    ) {
      const firstAssignmentWithoutReview = companiesAssignments.find(
        (assignment) => !assignment.hasReview,
      );
      if (firstAssignmentWithoutReview) {
        navigate(`/company-profile/${firstAssignmentWithoutReview.companyAffinityId}`);
      }
    }
  }, [companiesAssignments]);

  if (!company || !Object.keys(company).length) {
    return <PageLoading />;
  }

  const isNotFirstCompany =
    companiesAssignments.findIndex(
      (assignment) => assignment.companyAffinityId === company.id?.toString(),
    ) > 0;

  return (
    <div className={classNames('company-profile-page', { loading })} data-cy="company-profile-page">
      <Header toggleDialogs={toggleDialogs} isModalOpen={isModalOpen} onReachOut={handleReachOut} />
      {toast.type === ToastType.SUCCESS && toast.show && (
        <SuccessFeedbackToast
          hideToast={hideToast}
          hiding={toast.hiding}
          personalAffinityListId={user.user.personalAffinityListId}
        />
      )}
      {toast.type === ToastType.DANGER && toast.show && (
        <DangerFeedbackToast hideToast={hideToast} hiding={toast.hiding} />
      )}
      <Container>
        <div className="company-profile-page__container">
          <div className="company-profile-page__summary-container">
            <div className="company-profile-page__head">
              {loading ? (
                <div className="company-profile-page__logo loading">
                  <Skeleton variant="rounded" width={66} height={66} />
                  <Skeleton
                    variant="rounded"
                    width={120}
                    height={28}
                    sx={{ bgcolor: 'grey.200' }}
                  />
                </div>
              ) : (
                <a
                  href={company.websites[0]}
                  target="_blank"
                  className="company-profile-page__logo"
                  data-cy="company-logo"
                  rel="noreferrer"
                >
                  <div className="company-profile-page__img-name-container">
                    <img src={company.logo} alt="Logo" className="company-profile-page__logo-img" />
                    <span className="company-profile-page__company-name" data-cy="company-name">
                      {company.name}
                    </span>
                  </div>
                </a>
              )}
              <div className="company-profile-page__social-wrapper">
                <div
                  className="company-profile-page__social company-profile-page__social--border"
                  data-cy="company-social-websites"
                >
                  {company.websites?.map((website: string) => (
                    <WebsiteIcon
                      key={website}
                      website={website}
                      onClick={() => {
                        posthog?.capture('link_click', {
                          link_type: website,
                          company_id: company.id,
                          company_name: company.name,
                        });
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="company-profile-page__body" data-cy="company-page-body">
              <CollapsibleDescription description={company.description} />
              <InfoSidebar company={company} isLoading={loading} />
            </div>
            {company.prospectOrigin && (
              <ProspectOrigin prospectOrigin={company.prospectOrigin} loading={loading} />
            )}
            {company.affinityLists && company.affinityLists.length > 0 && (
              <AffinityList affinityLists={company.affinityLists} loading={loading} />
            )}
          </div>
          <div>
            <div className="company-profile-page__content-container" data-cy="company-page-content">
              {company.funding && (
                <FundraisingHistoryCard
                  loading={loading}
                  fundraisingData={[company.funding.last, ...company.funding.history]}
                  investors={company.investors}
                />
              )}
              {company.headcount && (
                <HeadcountCard
                  loading={loading}
                  dates={company.headcount.dates}
                  headcounts={company.headcount.departmentsCounts}
                />
              )}
              <AffinityNotesCard notes={company.notes || []} loading={loading} />
              {getInstagramProfile() && (
                <InstagramCard
                  name={company.name}
                  profile={getInstagramProfile()}
                  loading={loading}
                />
              )}
            </div>
            <div className="company-profile-page__headlines-card">
              <HeadlinesList headlines={company.headlines} loading={loading} />
            </div>
          </div>

          {isNotFirstCompany && <UndoToast />}
        </div>
      </Container>
      {isMobile && <StickyNavbar toggleDialogs={toggleDialogs} />}
      {isDontPursueModalOpen && (
        <ReasonsDialog
          isOpen={isDontPursueModalOpen}
          setIsOpen={setIsDontPursueModalOpen}
          reasons={DONT_REASONS}
          actionType={ButtonAction.DontPursue}
          textAreaReason={DONT_REASONS[DONT_REASONS.length - 1].id}
          feedbackToastAction={() => triggerToast(ToastType.DANGER)}
        />
      )}
      {isSkipModalOpen && (
        <ReasonsDialog
          isOpen={isSkipModalOpen}
          setIsOpen={setIsSkipModalOpen}
          reasons={SKIP_REASONS}
          actionType={ButtonAction.Skip}
          availableUsers={availableUsers}
          textAreaReason={SKIP_REASONS[SKIP_REASONS.length - 1].id}
        />
      )}
      {/* Commented by request https://github.com/Anthos-Capital/Forge-FE/issues/122
      {!isMobile && <GuideDialog isOpen={isGuideDialogOpen} setIsOpen={setIsGuideDialogOpen} />}
       */}
    </div>
  );
};

export default CompanyProfilePage;
